import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { User } from './pages/users/types'
import { useToast } from 'vuestic-ui'

const baseURL = import.meta.env.VITE_API_BASE_URL || `https://irebox-web.manosens.com/api`
const token = localStorage.getItem('token')
const { init } = useToast()

const axiosInstance = axios.create({
  baseURL,
})

const handleError = (error: any) => {
  if (axios.isAxiosError(error)) {
    // console.log('error message: ', error.message)
    // 👇️ error: AxiosError<any, any>
    return error.status
  } else {
    // console.log('unexpected error: ', error)
    return error
  }
}

if (token && token !== 'undefined') {
  const decodedToken = jwtDecode(token)
  const currentTime = Math.floor(Date.now() / 1000)

  if (decodedToken.exp != null && decodedToken.exp < currentTime) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  } else {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
}

// export const verify_token = async () => {
//   // used for verifying user NOT IN USE
//   axiosInstance.defaults.withCredentials = true
//   axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
//   const result = await axiosInstance.post('/auth/verify', {
//     "token": localStorage.getItem('token')
//   })
//   console.log(result.status)
//   return result.status === 200;
// }

export const createIreboxAlias = async (alias: string, uuid: string, user_id: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.post('/create-alias', {
    user_id: user_id,
    irebox_id: uuid,
    alias: alias,
  })
}

export const get_devices = async () => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/user-devices')
}

export const post_login = async (credentials: { email: string; password: string }) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
  return await axiosInstance.post('/auth/jwt/login', {
    username: credentials.email,
    password: credentials.password,
  })
}

export const logout = async () => {
  if (!localStorage.getItem('token')) return
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.post('/auth/jwt/logout')
}

export const get_user = async () => {
  if (!localStorage.getItem('token')) return { status: 401 }
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/users/me')
}

export const get_users = async () => {
  if (!localStorage.getItem('token')) return { status: 401 }
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get('/users-all')
}

export const get_dashboard_config = async (uuid: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  return await axiosInstance.get(`/dashboard-config?sn=${uuid}`)
}

export const get_bms_weights = async (uuid: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    const response = await axiosInstance.get(`/bms-weights?sn=${uuid}`)
    const { status } = response
    return status === 200 ? response.data : []
  } catch (error) {
    return handleError(error)
  }
}

export const register_user = async (credentials: { email: string; password: string }) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  return await axiosInstance.post('/auth/register', {
    email: credentials.email,
    password: credentials.password,
    ireboxes: [],
    is_ro: false,
    is_dashboard_only: false,
  })
}

export const updateUser = async (user: User) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    return await axiosInstance.patch(`/users/${user.id}`, {
      email: user.email,
      is_active: user.is_active,
      is_superuser: user.is_superuser,
      is_verified: user.is_verified,
      is_ro: user.is_ro,
      is_dashboard_only: user.is_dashboard_only,
      ireboxes: user.ireboxes,
    })
  } catch (e: any) {
    init({ message: 'Error modifying user. Response:' + e.status, color: 'danger' })
  }
}

export const updatePassword = async (password: string, token: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  try {
    const response = await axiosInstance.post('/auth/reset-password', { password: password, token: token })
    const { status } = response
    return status
  } catch (error) {
    return handleError(error)
  }
}

export const resetPassword = async (email: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  try {
    const response = await axiosInstance.post('/auth/forgot-password', { email: email })
    const { status } = response
    return status
  } catch (error) {
    return handleError(error)
  }
}

export const addUserInternal = async (user: User) => {
  const response = await register_user({ email: user.email, password: 'manosens123' })
  if (response.status === 201) {
    init({ message: 'User created successfully', color: 'success' })
  } else {
    init({ message: 'Error creating user. Response:' + response.status, color: 'danger' })
  }
  user.id = response.data.id
  await updateUser(user)
  return response
}

export const removeUser = async (user: User) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    return await axiosInstance.delete(`/users/${user.id}`)
  } catch (e: any) {
    init({ message: 'Error deleting user. Response:' + e.status, color: 'danger' })
  }
}

interface urlObjectType {
  [key: string]: string
}

const urlTypes = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
} as urlObjectType

export const get_cumulative_charts = async (
  uuid: string,
  type: string,
  interval: string,
  format: string | null = null,
  from: string | null = null,
  to: string | null = null,
  bucket_size: number | null = null,
) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  const response = await axiosInstance.get(
    `/cumulative-charts?sn=${uuid}&chart_type=${type}&interval=${interval}&output_format=${format}&from_date=${from}&to_date=${to}&bucket_size=${bucket_size}`,
    {
      responseType: format ? 'blob' : 'json',
    },
  )

  if (format) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: urlTypes[format] }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `chart_${type}_${interval}.${format}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    return response
  }
}

export const getDashboardSettings = async () => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    const response = await axiosInstance.get(`/dashboard-settings`)
    const { status } = response
    return status === 200 ? response.data : []
  } catch (error) {
    return handleError(error)
  }
}

export const addDashboardWidget = async (irebox_id: string, widget_name: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    const response = await axiosInstance.post(`/add-dashboard-widget`, {
      irebox_id: irebox_id,
      widget_name: widget_name,
    })
    const { status } = response
    return status
  } catch (error) {
    return handleError(error)
  }
}

export const removeDashboardWidget = async (irebox_id: string, widget_name: string) => {
  axiosInstance.defaults.withCredentials = true
  axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
  axiosInstance.defaults.headers.authorization = `Bearer ${localStorage.getItem('token')}`
  try {
    const response = await axiosInstance.post(`/remove-dashboard-widget`, {
      irebox_id: irebox_id,
      widget_name: widget_name,
    })
    const { status } = response
    return status
  } catch (error) {
    return handleError(error)
  }
}

export default axiosInstance
