{
  "auth": {
    "agree": "Souhlasím s",
    "createAccount": "Vytvořit účet",
    "createNewAccount": "Vytvořit nový účet",
    "email": "Email",
    "login": "Přihlásit se",
    "password": "Heslo",
    "forgot_password": "Zapomněli jste heslo?",
    "recover_password": "Obnovit heslo",
    "recovery_message": "Zadejte svůj email a my vám pošleme odkaz na obnovení hesla.",
    "sign_up": "Zaregistrovat se",
    "keep_logged_in": "Zůstat přihlášený",
    "termsOfUse": "Podmínky užívání.",
    "reset_password": "Obnovit heslo",
    "update_password": "Aktualizovat heslo"
  },
  "404": {
    "title": "Tato stránka je mimo provoz.",
    "text": "Pokud si myslíte, že to není správné, pošlete nám zprávu na ",
    "back_button": "Zpět na hlavní panel"
  },
  "typography": {
    "primary": "Primární textové styly",
    "secondary": "Sekundární textové styly"
  },
  "dashboard": {
    "versions": "Verze",
    "setupRemoteConnections": "Nastavení vzdálených připojení",
    "currentVisitors": "Aktuální návštěvníci",
    "navigationLayout": "rozložení navigace",
    "topBarButton": "Horní lišta",
    "sideBarButton": "Boční lišta"
  },
  "language": {
    "brazilian_portuguese": "Portugalština",
    "english": "Angličtina",
    "spanish": "Španělština",
    "simplified_chinese": "Zjednodušená čínština",
    "persian": "Perština"
  },
  "menu": {
    "auth": "Autorizace",
    "buttons": "Tlačítka",
    "timelines": "Časové osy",
    "dashboard": "Hlavní panel",
    "billing": "Fakturace",
    "login": "Přihlásit se",
    "preferences": "Předvolby účtu",
    "payments": "Platby",
    "settings": "Nastavení aplikace",
    "pricing-plans": "Cenové plány",
    "payment-methods": "Platební metody",
    "signup": "Zaregistrovat se",
    "recover-password": "Obnovit heslo",
    "recover-password-email": "Email pro obnovení hesla",
    "404": "404",
    "faq": "FAQ",
    "users": "Uživatelé",
    "projects": "Projekty",
    "faults": "Chybové hlášky",
    "battery": "Baterie",
    "home": "Domov",
    "profile": "Profil",
    "dashboard-settings": "Nastavení hlavního panelu"
  },
  "messages": {
    "all": "Zobrazit všechny zprávy",
    "new": "Nové zprávy od {name}",
    "mark_as_read": "Označit jako přečtené"
  },
  "navbar": {
    "messageUs": "Dotazy na vývoj webu:",
    "repository": "GitHub Rep"
  },
  "notifications": {
    "all": "Zobrazit všechny upozornění",
    "less": "Zobrazit méně upozornění",
    "mark_as_read": "Označit jako přečtené",
    "sentMessage": "vám poslal(a) zprávu",
    "uploadedZip": "nahrál(a) nový soubor typu Zip s {type}",
    "startedTopic": "zahájil(a) nové téma"
  },
  "user": {
    "language": "Změnit jazyk",
    "logout": "Odhlásit se",
    "profile": "Profil",
    "settings": "Nastavení",
    "billing": "Fakturace",
    "faq": "FAQ",
    "helpAndSupport": "Nápověda a podpora",
    "projects": "Projekty",
    "account": "Účet",
    "explore": "Prozkoumat"
  },
  "treeView": {
    "basic": "Základní",
    "icons": "Ikony",
    "selectable": "Vybratelné",
    "editable": "Editovatelné",
    "advanced": "Pokročilé"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Odeslat"
  },
  "spacingPlayground": {
    "value": "Hodnota",
    "margin": "Okraj",
    "padding": "Odsazení"
  },
  "spacing": {
    "title": "Rozestupy"
  },
  "cards": {
    "cards": "Karty",
    "fixed": "Pevné",
    "floating": "Plovoucí",
    "contentText": "Unikátní pruhy zeber z nich činí jedno z nejznámějších zvířat.",
    "contentTextLong": "Unikátní pruhy zeber z nich činí jedno z nejznámějších zvířat. Vyskytují se v různých biotopech, jako jsou savany, lesy, keřové porosty, hory a pobřežní kopce. Různé lidské aktivity měly závažný dopad na populace zeber, zejména lov pro kůži a ničení biotopů. Grévyho zebra a horská zebra jsou ohrožené. I když jsou zebry plání mnohem hojnější, jeden poddruh, quagga, vyhynul.",
    "rowHeight": "Výška řádku",
    "title": {
      "default": "Výchozí",
      "withControls": "S ovládacími prvky",
      "customHeader": "Vlastní záhlaví",
      "withoutHeader": "Bez záhlaví",
      "withImage": "S obrázkem",
      "withTitleOnImage": "S titulkem na obrázku",
      "withCustomTitleOnImage": "S vlastním titulkem na obrázku",
      "withStripe": "S pruhem",
      "withBackground": "S pozadím"
    },
    "button": {
      "main": "Hlavní",
      "cancel": "Zrušit",
      "showMore": "Zobrazit více",
      "readMore": "Přečíst více"
    },
    "link": {
      "edit": "Upravit",
      "setAsDefault": "Nastavit jako výchozí",
      "delete": "Smazat",
      "traveling": "Cestování",
      "france": "Francie",
      "review": "Recenze",
      "feedback": "Zanechat zpětnou vazbu",
      "readFull": "Přečíst celý článek",
      "secondaryAction": "Sekundární akce",
      "action1": "Akce 1",
      "action2": "Akce 2"
    }
  },
  "colors": {
    "themeColors": "Barvy tématu",
    "extraColors": "Extra barvy",
    "gradients": {
      "basic": {
        "title": "Gradienty tlačítek"
      },
      "hovered": {
        "title": "Hoverované gradienty tlačítek",
        "text": "Pro hover stav se aplikuje zesvětlení o 15 % původního stylu (gradient nebo plochá barva)."
      },
      "pressed": {
        "title": "Zmáčknuté gradienty tlačítek",
        "text": "Pro stisknutý stav se aplikuje ztmavení o 15 % původního stylu (gradient nebo plochá barva)."
      }
    }
  },
  "tabs": {
    "alignment": "Zarovnání záložek",
    "overflow": "Přetékání záložek",
    "hidden": "Záložky se skrytým posuvníkem",
    "grow": "Růst záložek"
  },
  "helpAndSupport": "Nápověda a podpora",
  "aboutVuesticAdmin": "O Vuestic Admin",
  "search": {
    "placeholder": "Hledat..."
  },
  "buttonSelect": {
    "dark": "Tmavý",
    "light": "Světlý"
  },
  "custom": {
    "language": "Jazyk",
    "turn_off_inverter": "Vypnout systém",
    "turn_on_inverter": "Zapnout systém",
    "regulation_active": "Vypnout regulaci",
    "regulation_inactive": "Zapnout regulaci",
    "min_max_regulation_active": "Vypnout Max/Min regulaci",
    "min_max_regulation_inactive": "Zapnout Max/Min regulaci",
    "emergency_stop": "Nouzové zastavení",
    "force_battery_charge": "Nucené nabíjení baterie",
    "set_charge": "Zapnout nabíjení/vybíjení",
    "unset_charge": "Vypnout nabíjení/vybíjení",
    "actual_regulation_state": "Aktuální stav regulace",
    "actual_charge_state": "Aktuální stav nabíjení",
    "actual_regulation_value": "Aktuální hodnota regulace",
    "actual_calculated_regulation": "Aktuální vypočtená regulace",
    "actual_regulation_Max_Min_state": "Aktuální stav Max/Min regulace",
    "actual_regulation_Max_Min_value": "Aktuální hodnota Max/Min regulace",
    "total": "Celkem",
    "total_active_power": "Celkový aktivní výkon",
    "l1_active_power": "L1 aktivní výkon",
    "l2_active_power": "L2 aktivní výkon",
    "l3_active_power": "L3 aktivní výkon",
    "l1_voltage": "L1 napětí",
    "l2_voltage": "L2 napětí",
    "l3_voltage": "L3 napětí",
    "pt": "PT",
    "pa": "PA",
    "pb": "PB",
    "pc": "PC",
    "p1_current": "Fáze 1 proud",
    "p2_current": "Fáze 2 proud",
    "p3_current": "Fáze 3 proud",
    "battery_1_soc": "Baterie 1 Nabití",
    "battery_2_soc": "Baterie 2 Nabití",
    "battery_3_soc": "Baterie 3 Nabití",
    "battery_4_soc": "Baterie 4 Nabití",
    "battery_5_soc": "Baterie 5 Nabití",
    "battery_6_soc": "Baterie 6 Nabití",
    "charts": "Grafy",
    "compound": "Areál",
    "current_per_phase": "Aktuální proud na fázi",
    "total_active_power_chart": "Graf celkového aktivního výkonu",
    "regulation_chart": "Graf regulace",
    "inverter_faults": "Chybové hlášky",
    "module_temperature": "Teplota modulu",
    "ambient_temperature": "Okolní teplota",
    "logout": "Odhlásit se",
    "last_update": "Poslední aktualizace",
    "loading_data": "Načítávání dat",
    "page_not_found": "Stránka nenalezena",
    "dont_have_account": "Nemáte účet?",
    "sign_up": "Vytvořte si účet",
    "password": "Heslo",
    "repeat_password": "Zopakujte heslo",
    "create_account": "Vytvořit účet",
    "login": "Přihláště se",
    "already_have": "Už máte účet?",
    "password_hint": "Heslo by mělo mít více než 8 znaků: písmena, aspoň jedno číslo a speciální znak.",
    "field_required": "Pole je povinné",
    "passwords_not_match": "Hesla se neshodují",
    "password_min_length": "Heslo by mělo mít více než 8 znaků",
    "password_one_letter": "Heslo by mělo obsahovat aspoň jedno písmeno",
    "password_one_number": "Heslo by mělo obsahovat aspoň jedno číslo",
    "password_one_special": "Heslo by mělo obsahovat aspoň jeden speciální znak",
    "email_invalid": "Email není platný",
    "unsaved_changes_form": "Máte neuložené změny. Opravdu chcete opustit tuto stránku?",
    "add_user": "Přidat uživatele",
    "edit_user": "Upravit uživatele",
    "add_irebox": "Přidat iRebox",
    "add": "Přidat",
    "edit": "Upravit",
    "save": "Uložit",
    "cancel": "Zrušit",
    "delete": "Smazat",
    "is_active": "Aktivní",
    "is_verified": "Ověřený",
    "is_superuser": "Administrátor",
    "is_dashboard_only": "Pouze hlavní panel",
    "ireboxes": "iReboxy",
    "hide": "Zavřít",
    "more_info": "Více informací",
    "delete_irebox": "Smazat iRebox",
    "delete_user": "Smazat uživatele",
    "delete_irebox_confirmation": "Opravdu chcete smazat iRebox {irebox} uživatele {user}?",
    "delete_user_confirmation": "Opravdu chcete smazat uživatele {user}?",
    "cannot_delete_yourself": "Nemůžete smazat sami sebe",
    "bms_data": "Dáta o Battery Management System",
    "battery_charging": "Baterie se nabíjí",
    "battery_discharging": "Baterie se vybíjí",
    "work_in_progress": "Pracujeme na tom",
    "battery_charge_total": "Celkové nabití baterie",
    "bms_soc_cumulative_chart": "Graf nabití baterií",
    "smart_meter_cumulative_chart": "Graf celkového výkonu a výkonu ve fázích",
    "total_active_power_cumulative_chart": "Graf celkového aktivního výkonu na střídači",
    "solar_power_cumulative_chart": "Graf solárního výkonu",
    "daily": "Den",
    "weekly": "Týden",
    "monthly": "Měsíc",
    "devices": "Zařízení",
    "search": "Hledat",
    "user_info": "Informace o uživateli",
    "edit_password": "Změnit heslo",
    "old_password": "Staré heslo",
    "new_password": "Nové heslo",
    "confirm_password": "Zopakujte nové heslo",
    "no_devices": "Žádná zařízení",
    "irebox_info": "Informace o zařízeních",
    "add_irebox_alias": "Přidat alias iReboxu",
    "back": "Zpět",
    "input_integer": "Zadejte celé číslo",
    "inputKW_invalid": "Zadejte platnou hodnotu v kW v rozsahu -500 až 500",
    "inputSoc_invalid": "Zadejte platnou hodnotu v % v rozsahu 0 až 100",
    "download": "Stáhnout",
    "download_format": "Stáhnout ve formátu",
    "custom": "Vlastní",
    "from": "Od",
    "to": "Do",
    "set": "Nastavit",
    "select_time_interval": "Vyberte časové rozmezí",
    "error_from_greater_than_to": "Hodnota Od musí být menší než Do",
    "bucket_size": "Velikost intervalu"
  },
  "error_messages": {
    "fetch_users": "Nepodařilo se načíst uživatele",
    "modify_user": "Nepodařilo se upravit uživatele, kod chyby: {error}"
  }
}
