<template>
  <div>
    <Transition name="accordion" @beforeEnter="beforeEnter" @enter="enter" @leave="leave">
      <div v-show="internalOpen" ref="content" class="accordion-content">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false, // Accordion is closed by default
    },
  },
  setup(props) {
    const internalOpen = ref(props.isOpen)
    const content = ref(null) // Reference to the accordion content div

    // Watch for changes in the prop `isOpen`
    watch(
      () => props.isOpen,
      (newValue) => {
        internalOpen.value = newValue // Update internal state based on prop
      },
    )

    // Toggle accordion open/close and emit an event
    const toggleAccordion = () => {
      internalOpen.value = !internalOpen.value
    }

    // Transition hooks
    const beforeEnter = (el) => {
      el.style.height = 0 // Initial height is 0
    }

    const enter = (el) => {
      el.style.height = el.scrollHeight + 'px' // Animate to full height
    }

    const leave = (el) => {
      el.style.height = el.scrollHeight + 'px' // Start with full height
      setTimeout(() => {
        el.style.height = 0 // Animate to height 0
      }, 0)
    }

    return {
      internalOpen,
      content,
      toggleAccordion,
      beforeEnter,
      enter,
      leave,
    }
  },
}
</script>

<style scoped>
.accordion-content {
  overflow: hidden;
  transition: height 0.3s ease;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: height 0.3s ease;
}
</style>
