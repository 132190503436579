<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)]">
    <template #left>
      <RouterLink
        class="bg-gradient-to-l from-blue-700 to-slate-700 h-full flex items-center justify-center"
        style="width: 35vw"
        to="/"
        aria-label="Visit homepage"
      >
        <h1 class="font-semibold text-4xl mb-4 text-white">iRebox Admin</h1>
      </RouterLink>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div>
        <RouterLink
          class="bg-gradient-to-l from-blue-700 to-slate-700 h-full flex items-center justify-center w-full"
          to="/"
          aria-label="Visit homepage"
        >
          <h1 class="font-semibold text-4xl mb-4 mt-4 text-white">iRebox Admin</h1>
        </RouterLink>
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px] p-6">
          <div class="flex flex-col items-start">
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'

const breakpoint = useBreakpoint()
</script>
